import request from '@/utils/index'
import qs from 'qs'

export const queryAppsList = () => {
  return request.get(`/admin/apps`)
}

export const queryAppDetail = (app_id: string) => {
  return request.get(`/admin/apps/${app_id}`)
}

export const querySpecimenClassification = () => {
  return request.get(`/public_api/resource_classification`)
}

export const querySpecimenImageList = (params: any) => {
  return request.get(`/public_api/specimen/image/search?${qs.stringify(params)}`)
}

export const algorithmParams = (algorithm_id: number) => {
  return request.get(`/admin/algorithm/${algorithm_id}/params`)
}
