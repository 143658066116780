






























import { Vue, Component } from 'vue-property-decorator'
import { importImage, uploadAppsImage } from '@/api/admin'
import { Form as ElForm } from 'element-ui/types/element-ui'
import { readFile } from '@/utils/readfile'
import { queryAppDetail } from '@/api/specimen'

@Component
export default class PhotoManagementDetail extends Vue {
  private form: any = {
    fileList: []
  }

  private dialogImageUrl = ''
  private dialogVisible = false
  private uploading = false

  private rules = {
    app_name: [{ required: true, message: '请填写应用名称' }],
    state: [{ required: true, message: '请选择应用状态' }]
  }

  private get readonly() {
    return this.$route.query.type == 'view'
  }

  private mounted() {
    this.query_app()
  }

  private async query_app() {
    const {
      app_id
    } = this.$route.query
    if (!app_id) return
    const { data } = await queryAppDetail(app_id as string)
    this.form = {
      state: data.state,
      app_name: data.app_name,
      app_id: app_id
    }
  }
  private handleChange(file: any, fileList: any) {
    const fileExt = file.name.replace(/.+\./, '')
    if (['jpg', 'png'].indexOf(fileExt.toLowerCase()) === -1) {
      this.$message.error('格式不符合要求，请上传.jpg、.png格式图片')
      fileList.splice(-1, 1)
      return
    }
    this.doImageUpload(file)
  }

  private doImageUpload(file: any) {
    this.uploading = true
    const callback = async (data: any) => {
      try {
        const { name, url } = await importImage(data)
        this.form.fileList.push({ url })
        this.form.image_name = name
        ;(this.$refs.form as ElForm).validateField('fileList')
      } catch (e) {
        console.log(e, 'doImageUpload')
      } finally {
        this.uploading = false
      }
    }
    readFile(file.raw, callback)
  }

  private handleRemove(file: any, fileList: any) {
    this.form.fileList = fileList
    this.$delete(this.form, 'fileList')
    this.$set(this.form, 'fileList', fileList)
    this.form.image_name = ''
  }

  private handlePictureCardPreview(file: any) {
    this.dialogVisible = true
    this.dialogImageUrl = file.url
  }

  private handleSave() {
    ;(this.$refs.form as ElForm).validate(async (valid: boolean) => {
      if (valid) {
        try {
          const {
            app_id
          } = this.$route.query
          const { app_name, state } = this.form
          const params = { app_name, state }
          await uploadAppsImage(params, app_id as string)
          this.$message.warning('保存成功')
          this.goBack()
        } catch (e) {
          console.warn(e, 'login')
        }
      } else {
        return false
      }
    })
  }

  private goBack() {
    this.$router.back()
  }
}
